import React from 'react'

// @TODO centralise theme
const logoFills = {
  black: '#000',
  // MyHR Blue
  blue: '#384fe5',
  white: '#fff',
}
type LogoColor = keyof typeof logoFills

export type LogoProps = {
  color?: LogoColor
}

export const Logo = ({ color = 'white' }: LogoProps): JSX.Element => {
  return (
    <a
      className="myhr-logo"
      href={process.env.BROCHURE_URL || '/'}
      title="MyHR - Make it easy"
    >
      {getLogoByColor(color)}
    </a>
  )
}

const getLogoByColor = (color: LogoColor) => {
  return color == 'white' ? getLogoWhite() : getLogoWithFillColor(color)
}

/**
 * @see MyHR_Logo-final-RGB_Reverse_Small
 */
const getLogoWhite = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 231 90"
      aria-label="MyHR Make it easy"
    >
      <defs>
        <style dangerouslySetInnerHTML={{ __html: '.cls-1 { fill: #fff; }' }} />
      </defs>
      <path
        className="cls-1"
        d="M179.4 69.31V16.8h-12.03v19.85h-18.36V16.8h-12.03v52.51h12.03V46.29h18.36v23.02h12.03zM198.2 27.47h10.47c4.14 0 6.61 2.26 6.61 6.06 0 4.08-2.72 6.92-6.61 6.92H198.2V27.47Zm8.18 22.67 8.81 19.06h14.33l-11.39-21.53.35-.17c4.13-2.04 9.05-6.28 9.05-14.92 0-9.87-6.93-15.77-18.55-15.77h-22.65v52.51h11.86V49.93h8.08l.1.22ZM128.4 16.55c-1.56-.98-4.33-1.57-7.41-1.57-.99 0-1.91.06-2.63.18-.02 2.92-.63 10.33-1.69 20.43-.71 6.81-3.77 26.75-8.61 27.51-1.59.25-2.38-1.72-2.38-1.72-1.52-4.28-1.89-18.59-1.01-39.43.13-3.01-.81-4.28-3.59-4.84-.77-.19-1.65-.28-2.62-.28-1.99 0-3.77.39-4.59.67-6.04 37.09-11.45 44.66-11.68 44.97 0 0-1.11 1.83-1.26.03-.15-1.79-1.57-31.08-1.71-49.54 0-.86-.32-1.53-.99-2.11-2.05-1.79-6.56-1.98-8.4-1.98h-.5c-9.42 18.22-14.76 40.62-14.76 40.62s-.91-44.68-1.21-45.85c-.18-.6-1.57-2.21-6.34-2.21-1.49 0-3.03.12-4.57.35-2.52 3.29-10.84 21.75-15.29 47.63 0 0-.59-30.32-1.46-33.8-4.45-4.45-6.67-4.02-6.83-4-2.41.28-14.19 7.62-17.36 19.96-.07 1.02-.05 1.66.82 2.97 2.26 3.39 6.79 3.97 6.9 3.99 1.98-6.99 7.97-14.19 7.97-14.19.32.24 1.76 34.89 3.99 49.37.04.13.28.92.94 1.28 1.56.86 4.24.8 7.73-.2.94-6 5.04-27.25 11.96-49.1l2.16-6.82s3.36 48.62 3.58 50.71c.18.31 1.27 1.8 5.11 1.8.92 0 3.84.42 4.55-2.81 2.26-10.32 10.2-36.22 10.28-36.48l1.43-4.48s.2 17.91 1.96 34.78c.14 1.35 1.64 7.99 7.88 8.03 1.31 0 3.35-.69 5.03-2.75 2.26-2.77 4.1-6.8 6.69-14.15l2.49-8.53s.06 9.27 1.22 14.12c1.06 4.42 3.36 10.03 8.35 11.65.74.24 1.49.37 2.22.37 8.29 0 13.39-15.57 13.44-15.73l1.6-4.97-.22 8.73c-.01.47-.2 7.15-1.59 14.3-.36 1.44-2.42 10.29-8.55 9.7 0 0 .45 2.78 2.92 4.28 4.15 2.51 8.33 0 8.35-.02 5.11-2.67 6.82-13.85 6.98-14.93.74-5.11 2.42-20.58 2.09-53.86 0-.84-.45-1.51-1.38-2.1Z"
      />
    </svg>
  )
}

/**
 * @see MyHR_Logo-final-RGB_Blue_Small
 */
const getLogoWithFillColor = (color: LogoColor) => {
  const fillColor = logoFills[color]

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 231 90"
      aria-label="MyHR Make it easy"
    >
      <path
        fill={fillColor}
        d="M179.4 69.31V16.8h-12.03v19.85h-18.36V16.8h-12.03v52.51h12.03V46.29h18.36v23.02h12.03zM198.2 27.47h10.47c4.14 0 6.61 2.26 6.61 6.06 0 4.08-2.72 6.92-6.61 6.92H198.2V27.47Zm8.18 22.67 8.81 19.06h14.33l-11.39-21.53.35-.17c4.13-2.04 9.05-6.28 9.05-14.92 0-9.87-6.93-15.77-18.55-15.77h-22.65v52.51h11.86V49.93h8.08l.1.22ZM128.4 16.55c-1.56-.98-4.33-1.57-7.41-1.57-.99 0-1.91.06-2.63.18-.02 2.92-.63 10.33-1.69 20.43-.71 6.81-3.77 26.75-8.61 27.51-1.59.25-2.38-1.72-2.38-1.72-1.52-4.28-1.89-18.59-1.01-39.43.13-3.01-.81-4.28-3.59-4.84-.77-.19-1.65-.28-2.62-.28-1.99 0-3.77.39-4.59.67-6.04 37.09-11.45 44.66-11.68 44.97 0 0-1.11 1.83-1.26.03-.15-1.79-1.57-31.08-1.71-49.54 0-.86-.32-1.53-.99-2.11-2.05-1.79-6.56-1.98-8.4-1.98h-.5c-9.42 18.22-14.76 40.62-14.76 40.62s-.91-44.68-1.21-45.85c-.18-.6-1.57-2.21-6.34-2.21-1.49 0-3.03.12-4.57.35-2.52 3.29-10.84 21.75-15.29 47.63 0 0-.59-30.32-1.46-33.8-4.45-4.45-6.67-4.02-6.83-4-2.41.28-14.19 7.62-17.36 19.96-.07 1.02-.05 1.66.82 2.97 2.26 3.39 6.79 3.97 6.9 3.99 1.98-6.99 7.97-14.19 7.97-14.19.32.24 1.76 34.89 3.99 49.37.04.13.28.92.94 1.28 1.56.86 4.24.8 7.73-.2.94-6 5.04-27.25 11.96-49.1l2.16-6.82s3.36 48.62 3.58 50.71c.18.31 1.27 1.8 5.11 1.8.92 0 3.84.42 4.55-2.81 2.26-10.32 10.2-36.22 10.28-36.48l1.43-4.48s.2 17.91 1.96 34.78c.14 1.35 1.64 7.99 7.88 8.03 1.31 0 3.35-.69 5.03-2.75 2.26-2.77 4.1-6.8 6.69-14.15l2.49-8.53s.06 9.27 1.22 14.12c1.06 4.42 3.36 10.03 8.35 11.65.74.24 1.49.37 2.22.37 8.29 0 13.39-15.57 13.44-15.73l1.6-4.97-.22 8.73c-.01.47-.2 7.15-1.59 14.3-.36 1.44-2.42 10.29-8.55 9.7 0 0 .45 2.78 2.92 4.28 4.15 2.51 8.33 0 8.35-.02 5.11-2.67 6.82-13.85 6.98-14.93.74-5.11 2.42-20.58 2.09-53.86 0-.84-.45-1.51-1.38-2.1Z"
      />
    </svg>
  )
}
