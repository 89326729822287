import { SxProps, Theme } from '@mui/material'

/**
 * Use this instead of `sx={{ ...sx1, ...(Array.isArray(sx) ? sx : [sx]) }}` to combine two `sx` props.
 *
 *   sx={[
 *       { lineHeight: '20px' },
 *       ...(Array.isArray(sx) ? sx : [sx]),
 *       ]
 *     }
 *
 *   sx={ sxCombine({ lineHeight: '20px' }, sx) }
 *
 */
export const sxCombine = (sx1: SxProps<Theme>, sx2?: SxProps<Theme>) => [
  ...(Array.isArray(sx1) ? sx1 : [sx1]),
  ...(Array.isArray(sx2) ? sx2 : [sx2]),
]
